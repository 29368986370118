.top {
    padding: 1em 2em;
    max-width: 900px;
}

form {
    width: 100%
}

.input_group {
    padding: 1em 0
}

.input_label {
    width: 15em
}

.small_text {
    width: 30em
}

.small_number {
    width: 5em
}



