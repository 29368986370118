Body,
.App {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;
    width: 100vw;
    height: 100vh;
}

.page {
    overflow: auto;
    flex-grow: 1;
    height: calc(100vh - 214px)
}

